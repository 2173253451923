/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const onClientEntry = () => {
    window.onload = () => {
      setTimeout(() => {
        const loadingIndicator = document.querySelector('#sh__is-loading');
        loadingIndicator && loadingIndicator.parentNode.removeChild(loadingIndicator);
      }, 1000);
    }
};
